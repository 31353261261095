import React, { useRef, useEffect } from 'react';

const Footer = () => {
    const d = new Date();
    return (
        <div className="font-dosis text-gray-400 place-items-center animate-fadein pt-4">
            Copyright © {d.getFullYear()} Black Paddle LLC. All rights reserved.
        </div>
    );
};

export default Footer;
